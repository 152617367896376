#parliament-tv{
  .tabs-panel{
    padding-top: 30px;
  }

  .tabs-title{
    margin-right: 10px;
  }


h3, h4, h5{
  color:$green;
}
h5{
  font-size: 1rem;
}


  th{
    background: $dark-gold;
    color:$white;
  }


  table{
    margin-bottom: 30px;
  }

}


#parliament-tv-banner{
  background-color: $dark-gold;
  @include fullscreen-background;
  background-image:url('/storage/app/media/BGs3_top_header.png');
  background-blend-mode: screen;
  color:$white;
  font-weight: bold;
  padding: 30px;
  font-family: $header-font-family;
  p{
    margin: 0;
    font-size: 1.125rem;
  }
  a{
    color:$white!important;
  }
  margin-bottom: 30px;
}


#youtube-channel-banner{
  background-color: $youtube;
  @include fullscreen-background;
  background-image:url('/storage/app/media/BGs3_top_header.png');
  background-blend-mode: screen;
  color:$white;
  font-weight: 500;
  padding: 30px;
  font-size: 1.5rem;
  margin-top: 30px;
  font-family: $header-font-family;
  position: relative;
  p{
    margin: 0;
    display: inline-block;
    line-height: 1.2;
  }
  padding-left: 90px;
  ion-icon{
    position: absolute;
    left: 30px;
    @include vertical-center;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}
