#hero-slider, #hero-slider-nav{
  list-style: none;
  margin: 0;
  padding: 0;
  li{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    position: relative;
    .caption{
      text-transform: uppercase;
      font-family: $header-font-family;
      font-weight: 600;
      font-size: 2rem;
      position: absolute;
      bottom:0;
      left: 0;
      width: 100%;
      padding: 20px;
      color:$white;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6c4d23+0,6c4d23+99&0+1,0.2+19,1+100 */
      background: -moz-linear-gradient(top,  rgba(108,77,35,0) 0%, rgba(108,77,35,0) 1%, rgba(108,77,35,0.2) 19%, rgba(108,77,35,0.99) 99%, rgba(108,77,35,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(108,77,35,0) 0%,rgba(108,77,35,0) 1%,rgba(108,77,35,0.2) 19%,rgba(108,77,35,0.99) 99%,rgba(108,77,35,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(108,77,35,0) 0%,rgba(108,77,35,0) 1%,rgba(108,77,35,0.2) 19%,rgba(108,77,35,0.99) 99%,rgba(108,77,35,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006c4d23', endColorstr='#6c4d23',GradientType=0 ); /* IE6-9 */

    }
  }

  .slick-slide{
    outline: none;
    border:none;
  }


.slick-next, .slick-prev{
  position: absolute;
  @include vertical-center;
  z-index: 99;
  color:$white;
  @include single-transition;
  cursor: pointer;
  ion-icon{
    width: 30px;
    height: 30px;
  }
}


@include breakpoint(large){
  .slick-next, .slick-prev{
    opacity: 0;
  }
  &:hover, &:focus{
    .slick-next, .slick-prev{
      opacity: 1;
    }
  }
}


.slick-prev{
  left: 10px;
}
.slick-next{
  right: 10px;
}

}

#hero-slider{
  padding-top: 5px;
  padding-bottom: 5px;
}

#hero{
  margin-top: 20px;
  margin-bottom: 20px;
}

#hero-slider-nav{

}

#hero-slider-nav .slick-slide{
  //margin-left: -5px;
  //margin-right: -5px;

  display: flex;
  flex-wrap: wrap;
  & > div{
    //height: 100%;
    display: flex;
    height: calc(100% / 3);

    li{
      padding: 5px;
      .caption{
        width: calc(100% - 10px);
        left: 5px;
        bottom:5px;
        padding: 10px;
        font-size: 1rem;
        .inner{
          max-height: 40px;
          overflow: hidden;
        }
        overflow: hidden;
        line-height: 1.3;
      }
    }
  }
}

#hero-slider-nav{

  img{
    height: 100%;
    object-fit: cover;
  }
   display: flex;
  //  height: 100%;
  .slick-track{
    height: 100%;
  }

}



.slick-slider{
  margin: 0;

}

.slick-slider{
  visibility: hidden;
}
.slick-slider.slick-initialized{
  visibility: visible;
}





.hero-banner{
  position: relative;
  min-height: 400px;
  margin-bottom: 30px;
  .banner-image{
    z-index: 0;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }
  padding: 40px 0;
  .banner-content{
    position: relative;
    z-index: 1;
    background: $green;
    color:$white;
    padding: 30px;
  }
}



.magazineFront.carousel{
  .slick-slide{
    outline: none;
    border:none;
  }


.slick-next, .slick-prev{
  position: absolute;
  @include vertical-center;
  z-index: 99;
  color:$gold;
  @include single-transition;
  cursor: pointer;
  ion-icon{
    width: 30px;
    height: 30px;
  }
}

.slick-prev{
  left: 0px;
}
.slick-next{
  right: 0px;
}


@include breakpoint(large){
  .slick-next, .slick-prev{
    opacity: 0.7;
  }
  &:hover, &:focus{
    .slick-next, .slick-prev{
      opacity: 1;
    }
  }
}
}
