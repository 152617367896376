@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1;
  }

  &::after {
    clear: both;
  }
}


@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/// Horizontally centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/// Absolutely centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-background{
  -webkit-background-size:cover;
  -moz-background-size:cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin quick-transition {
  -webkit-transition: 0.1s ease-in-out;
     -moz-transition: 0.1s ease-in-out;
     -o-transition: 0.1s ease-in-out;
     transition: 0.1s ease-in-out;
   }

@mixin single-transition {
  -webkit-transition: 0.25s ease-in-out;
     -moz-transition: 0.25s ease-in-out;
     -o-transition: 0.25s ease-in-out;
     transition: 0.25s ease-in-out;
   }


@mixin double-transition {
  -webkit-transition: 0.5s ease-in-out;
     -moz-transition: 0.5s ease-in-out;
     -o-transition: 0.5s ease-in-out;
     transition: 0.5s ease-in-out;
   }





.green{
  color:$green!important;
}
.gold{
  color:$gold!important;
}
.dark-gold{
  color:$dark-gold!important;
}


.light-gold-bg{
  background-color: $light-gold;
}
