#in-session-widget{

  color:$gold;
  font-size: 14px;

  h4{
    background: $dark-gold;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  border:1px solid $light-gold;

  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child,
  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child ~ li {
    width: 50%;

  }
  .tabs{
    border:none;
    background: $inactive-tab;
    border-bottom: 3px solid $yellow;
  }
  .tabs-title{
    color:$brown;
    a{
      background: $inactive-tab;
      color:$brown;
      font-size: 1.125rem;
      font-family: $header-font-family;
      text-transform: uppercase;
    }
    &.is-active a{
      background: $yellow;
    }
  }
  .tabs-content{
    background: $white;
  //  color:$gold;
  border:none;
  }

  .button-wrapper{
    text-align: right;
    padding: 10px;
    .button{
      margin: 0;
    }
    border-top:1px solid $light-gold;

  }


  .publication{
    margin-bottom: 0.5rem;
  }

  .publication-wrapper{
    font-size: 0.8rem;
    font-family: $header-font-family;
  }

  border-bottom: 3px solid $yellow;


}
