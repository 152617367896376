@import "widgets/looking-for";
@import "widgets/did-you-know";
@import "widgets/visit-parliament";
@import "widgets/contact-member-of-parliament";
@import "widgets/parliament-tv";
@import "widgets/distribution-of-seats";
@import "widgets/in-session";
@import "widgets/press-releases";
@import "widgets/presiding-officers";
@import "widgets/social-feeds";
@import "widgets/whats-on";
@import "widgets/latest-bills";
@import "widgets/instagram";
@import "widgets/hp-gallery";
@import "widgets/hp-slider";
.sidebar{
  .sidebar-widget{
    margin-bottom: 30px;
  }
}
