#hp_photo_gallery {
    border: 1px solid #f6f3ec;
    border-bottom: 3px solid $dark-gold;
    
    #hp_gallery {
        padding: 6px;
        
        > div {
            width: calc(33% - 5px);;
            margin: 7px 3px;
            
            img {
                &.img-responsive {
                    width: 100%;
                }
            }
        }
    }
}