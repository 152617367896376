.insta-feed {
    width: 100%;

    h4 {
        width: 100% !important;
        height: auto !important;
        text-align: left;

        background: $instagram;
        padding:10px 15px;
        color:$white;
        text-transform: uppercase;
        margin-bottom: 0;
        padding-left: 15px;
        
        ion-icon {
          position: relative;
          top:2px;
          margin-right: 15px;
        }
    }
    
    .insta-images {        
        .insta-count {
            display: none;
        }
    }
}