#press-release-tabs-widget{

  font-size: 14px;
  border-bottom: 3px solid $dark-gold;

  .tabs{
    border:none;
    background: $inactive-tab;
    border-bottom: 3px solid $dark-gold;;
  }

  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child,
  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child ~ li {
    width: 50%;

  }


  .tabs-title{
    color:$white;
    a{
      background: $inactive-tab;
      color:$brown;
      font-size: 1.25rem;
      font-family: $header-font-family;
      margin: 0;
      padding: 15px;
    }
    &.is-active a{
      background: $dark-gold;
       color:$white;
    }
  }
  .tabs-content{
    background: $white;
    border:1px solid $light-gold;
      //  color:$gold;
    padding:0;
    border:none;
    border-right: 1px solid $light-gold;
    a{
      display: block;
    }
    .story-headline{
      padding: 10px;
      padding-left: 25px;
      position: relative;
      font-family: $header-font-family;
      border-bottom: 1px solid $light-gold;
      &:before{
        content:"";
        border-left: 15px solid $dark-gold;
        display: block;
        position: absolute;
        height: calc(100% - 4px);
        left: 0;
        top:2px;
      }
    }

  }
  .tabs-panel{
    padding: 0;
  }

  .button-wrapper{
    text-align: right;
    padding: 10px;
    .button{
      margin: 0;
      display: inline-block;
    }
    border-left:1px solid $light-gold;
  }

}
