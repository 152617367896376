#presiding-officers{
  //color:$gold;
  font-size: 14px;
  border: 1px solid $light-gold;
  border-bottom: 3px solid $dark-gold;


  h4{
    background: $dark-gold;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
  }


  .thumbnails{
    border-top: 0;
    padding: 15px;
    h6{
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    img{
      border-top: 3px solid $dark-gold;
      margin-bottom: 15px;
    }
    }
    small{
      color:$gold;
      line-height: 1.5;
      display: block;
    }


  ul{
    list-style: none;
    padding: 0;margin:0;

    li{
      background: lighten($gold, 40%);
      border-bottom: 1px solid lighten($gold, 30%);
      padding: 10px;
      padding-left: 25px;
      position: relative;
      &:before{
        content:"";
        border-left: 15px solid $dark-gold;
        display: block;
        position: absolute;
        height: calc(100% - 4px);
        left: 0;
        top:2px;
      }
      a{
        color: $green;
        text-transform: uppercase;
        font-family: $header-font-family;
        font-weight: 600;
      }
    }
  }


}
