#youtube-feed{
  .logo{
    max-width: 40px;
    margin-right: 15px;
  }
  .subscribe{
    padding: 10px;
    color:$black;
    font-size: 14px;
    strong{
      line-height: 1.2;
      display: block;
    }
    *:last-child{
      margin-bottom: 0;
    }
  }

  .title{
    padding-left: 15px;
  }

  border:1px solid $light-gold;

  font-size: 14px;
  border-bottom: 3px solid $youtube;

  h4{
    background: $youtube;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-left: 15px;
    ion-icon{
      position: relative;
      top:2px;
      margin-right: 15px;
    }
  }
  .video-cell{
    border-top:1px solid $light-gold;
    padding:10px;
  }
  *:last-child{
    margin-bottom: 0;
  }

  .button-wrapper{
    padding: 15px;
    text-align: right;
    border-top:1px solid $light-gold;
    .button{
      margin: 0;
    }
  }

}




#twitter-feed{

  .logo{
    max-width: 40px;
    margin-right: 15px;
  }
  .subscribe{
    padding: 10px;
    color:$black;
    font-size: 14px;
    strong{
      line-height: 1.2;
      display: block;
    }
    *:last-child{
      margin-bottom: 0;
    }
  }

  .title{
    padding-left: 15px;
  }

  border:1px solid $light-gold;

  font-size: 14px;
  border-bottom: 3px solid $twitter;

  h4{
    background: $twitter;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-left: 15px;
    ion-icon{
      position: relative;
      top:2px;
      margin-right: 15px;
    }
  }
  .twitter-cell{
    ion-icon{
      position: relative;
      top:2px;
      margin-right: 5px;
    }
    border-top:1px solid $light-gold;
    padding:10px;
    a{
      color:$twitter;
    }
  }
  *:last-child{
    margin-bottom: 0;
  }

  .button-wrapper{
    padding: 15px;
    text-align: right;
    border-top:1px solid $light-gold;
    .button{
      margin: 0;
    }
  }

}




#facebook-feed{

  .logo{
    max-width: 40px;
    margin-right: 15px;
  }
  .subscribe{
    padding: 10px;
    color:$black;
    font-size: 14px;
    strong{
      line-height: 1.2;
      display: block;
    }
    *:last-child{
      margin-bottom: 0;
    }
  }

  .title{
    padding-left: 15px;
  }

  border:1px solid $light-gold;

  font-size: 14px;
  border-bottom: 3px solid $facebook;

  h4{
    background: $facebook;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-left: 15px;
    ion-icon{
      position: relative;
      top:2px;
      margin-right: 15px;
    }
  }
  *:last-child{
    margin-bottom: 0;
  }
  .facebook-cell{
    ion-icon{
      position: relative;
      top:2px;
      margin-right: 5px;
    }
    border-top:1px solid $light-gold;
    padding:10px;
    a{
      color:$facebook;
    }
  }

  .button-wrapper{
    padding: 15px;
    text-align: right;
    border-top:1px solid $light-gold;
    .button{
      margin: 0;
    }
  }

}
