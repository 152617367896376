.button, button.button{

  text-transform: uppercase;
  font-family: $header-font-family;
  font-weight: 600;

  &.brown{
    background: $brown;
    color:$white!important;
    &:hover, &:focus{
      background: darken($brown, 10%);
    }
  }

  &.orange{
    background: $orange;
    color:$white!important;
    &:hover, &:focus{
      background: darken($orange, 10%);
    }
  }

  &.green{
    color:$white!important;
  }

  &.yellow{
    background: $yellow;
    color:$white!important;
    &:hover, &:focus{
      background: darken($yellow, 10%);
    }
  }
  &.dark-yellow{
    background: $dark-yellow;
    color:$white!important;
    &:hover, &:focus{
      background: darken($dark-yellow, 10%);
    }
  }

  &.gold{
    background: $gold;
    color:$white!important;
    &:hover, &:focus{
      background: darken($gold, 10%);
    }
  }

  &.dark-gold{
    background: $dark-gold;
    color:$white!important;
    &:hover, &:focus{
      background: darken($dark-gold, 10%);
    }
  }
  &.facebook{
    background: $facebook;
    color:$white!important;
    &:hover, &:focus{
      background: darken($facebook, 10%);
    }
  }
  &.twitter{
    background: $twitter;
    color:$white!important;
    &:hover, &:focus{
      background: darken($twitter, 10%);
    }
  }
  &.youtube{
    background: $youtube;
    color:$white!important;
    &:hover, &:focus{
      background: darken($youtube, 10%);
    }
  }

}
