#project-events-banner{
  @include fullscreen-background;
  margin-top: -30px;
  margin-bottom: 30px;
  height: auto;
  img{
    width: 100%;
  }
}

.page-project-details #content{
  @include breakpoint(medium){
    iframe{
      width: 100%;
      min-height: 420px;
    }
  }

}



.right-sidebar-content{
  padding: 20px;
  background: $light-gold;
  color:$dark-gold;
  & > br:first-of-type{
    display: none;
  }
  &.hr{
    br{
      display: none;
    }
    padding-bottom: 0;
    padding: 0;
    margin-top: 30px;
    h5{
      padding: 20px;
      margin-bottom: 0px;
      background: $dark-gold;
      color:$white;
    }
  }
}
