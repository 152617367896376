// Contact Member of Parliament

#contact-member-of-parliament{
  width: 100%;
  background: $dark-green;
  background-image:url('/storage/app/media/icon-logo.png');
  background-position: 200px center;
  background-size: 100%;
  background-repeat: no-repeat;
  color:$white;
  padding:20px;
  .input-group{
    margin-bottom: 0;
    input[type="text"]{
      font-size: 14px;
    }
  }

.input-group > :last-child, .input-group > :last-child.input-group-button > *{
  width: auto;
}

  h4{
    text-transform: uppercase;
    color:$white;
  }
  font-size: 14px;
}

// Featured Member
#featured-member{
  width: 100%;

  padding: 20px;
  padding-top: 0;
  background-color: $light-gold;
  color:$dark-gold;
  font-size: 14px;
  h6{
    margin-bottom: 0.5rem;
  }
  .label{
    margin-bottom: 1rem;
  }

  .thumbnail-image{
    border-top: 3px solid $dark-gold;
  }
  .details{
    padding-left: 15px;
    word-wrap: break-word;
  }
  h4{
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    color:$white;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    background-image:url('/storage/app/media/featured-member-header.png');
    background-color: $dark-gold;
    @include fullscreen-background;
  }

  #list-committees{
    margin-top: 1rem;
    h6{
      font-size: 1rem;
      text-transform: uppercase;
    }
    ul{
      list-style: square;
      color:$green;
      margin-bottom: 0;
    }
  }
}
