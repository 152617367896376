#whats-on-widget {
    font-size: 14px;
    border: 1px solid $light-gold;
    border-bottom: 3px solid $dark-gold;
    h4 {
        background: $dark-gold;
        padding: 10px 15px;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    #whats-on-slider {
        .header {
            text-align: center;
            background: $dark-yellow;
            padding: 15px;
            font-size: 1.125rem;
            font-family: $header-font-family;
            color: $white;
        }
        .slick-prev, .slick-next {
            position: absolute;
            z-index: 9;
            top: 15px;
            color: $white;
            ion-icon {
                width: 30px;
                height: 30px;
                margin-top: 10px;
            }
        }
        .slick-arrow {
            &:before {
                display: none;
            }
        }
    }
    .slick-prev {
        left: 10px;
    }
    .slick-next {
        right: 10px;
    }
    .start-time {
        padding: 10px;
        background: $green;
        text-align: center;
        color: $white;
    }
    .event-details {
        padding: 10px;
    }
    .label {
        padding: 8px;
        border-radius: 3px;
        display: block;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        margin-right: 15px;
    }
    .event {
        border-bottom: 1px solid $light-gold;
        padding-top: 1px;
        padding-bottom: 1px;
        background: $white;
    }
}
*:last-child {
    margin-bottom: 0;
}
.button-wrapper {
    padding: 15px;
    text-align: right;
    border-top: 1px solid $light-gold;
    .button {
        margin: 0;
    }
}
