#site-footer{
  border-bottom: 20px solid $dark-gold;
  margin-top: 30px;
  padding: 40px 0;
  background-color: $green;
  @include fullscreen-background;
  background-image:url('/storage/app/media/bg-2_footer.png');
  background-blend-mode: multiply;
  color:$white;
  a{
    color:$white;
  }

  font-size: 14px;

  h6{
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  h6:not(:first-child){
    margin-top: 30px;
  }



  .social-media-follow{
    margin-left: -5px;
    margin-right: -5px;
    a{
      margin-left: 5px;
      margin-right: 5px;
    }

      ion-icon{
        width:30px;
        height:30px;
      }
  }

}
