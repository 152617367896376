// Visit Parliament

#visit-parliament{
  height: auto;
  background-color: $light-green;
  position: relative;
  *{
    position: relative;
    z-index: 1;
  }
  &:before{
    position: absolute;
    content:"";
    display: block;
    @include fullscreen-background;
    background-color: $light-green;
    background-image:url('/storage/app/media/bg-2_small.png');
    background-blend-mode: multiply;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    opacity: 0.3;
    z-index: 0;
  }

  a{
    color: $white;
  }
  color:$white;
  padding: 20px;
  h4{
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  ul{
    min-width: 100%;
    width: calc(100% + 40px);
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    li{
      background-color: rgba(39, 89, 55, 0.45);
      padding: 10px 20px;
      margin-bottom: 1px;
      font-family: $header-font-family;
      text-transform: uppercase;
      font-size: 20px;
      img{
        width: 25px;
        margin-right: 1rem;
      }
    }
  }
}

.grid-x > #visit-parliament{
    height: auto;
    margin-bottom: 20px;
}
