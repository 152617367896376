// Did You Know
#did-you-know{
  padding: 20px;
  background-color: rgba(199, 153, 0, 0.7);
  @include fullscreen-background;
  background-image:url('/storage/app/media/icon-logo.png');
  background-blend-mode: screen;
  .faq{
    border-top: 1px dashed $brown;
    border-bottom: 1px dashed $brown;
    padding: 1rem 0;
    p:last-child{
      margin-bottom: 0;
    }
    margin-bottom: 1rem;
  }
  color:$brown;
  h4{
    text-transform: uppercase;
  }
  a{
    margin-bottom: 0;
  }
}
