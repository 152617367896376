.page-header{
  background: $green;
  padding:20px 10px;
  margin-bottom: 10px;
  @include breakpoint(medium){
    padding:20px 10px;
    margin-bottom: 20px;
  }
  @include breakpoint(large){
    padding:23px;
    margin-bottom: 30px;
  }
  h1{
    color:$white;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  @include fullscreen-background;
  background-image:url('/storage/app/media/bg-2_header.png');
  background-blend-mode: multiply;

  .breadcrumbs{
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    font-family: $header-font-family;
    color:$dark-yellow;
    text-transform: uppercase;
    a{
      color:$dark-yellow;
    }
  }
}


.content-thumbnail{
  text-align: center;
  background: $dark-yellow;
  color:$white;
  font-size: 14px;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  .image{
    position: relative;
    padding-top: 56%;
    width: 100%;
    img{
        position: absolute;
        left: 0;
        top:0;
        object-fit: cover;
        height: 100%;
        width: 100%;
        min-width: none;
    }
  }

  a:not(.button){
    display: block;
    width: 100%;
    color:$white;
  }
  .content{
    padding: 20px;
    width: 100%;
    *:last-child{
      margin-bottom: 0;
    }
  }
}


hr.dashed{
  border-style: dashed;
  border-bottom:1px dashed darken($light-gold, 10%);
  margin-top: 30px;
  margin-bottom: 30px;
}



.accordion{
  border:none;
}
.accordion-content{
  border:none;
}
.accordion-item{
  border-bottom:1px solid $white;

}
.accordion-title{
  &:hover, &:focus{
    background: darken($light-gold, 5%);
    color:$dark-gold;
  }
  font-family: $header-font-family;
  font-size: 1.5rem;
  color:$dark-gold;
  background: $light-gold;
  border:none;
  &:before{
    @include vertical-center;
    font-size: 35px;
    margin: 0;
  }
}
