#distribution-of-seats{

  color:$brown;
  background: $light-yellow;
  border-bottom:3px solid $yellow;

  h4{
    background: $dark-gold;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child,
  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child ~ li {
    width: 50%;

  }

  .tabs{
    border:none;
    background: $inactive-tab;
    background:$inactive-tab;
    border-bottom: 3px solid $dark-gold;

  }
  .tabs-title{
    color:$brown;
    a{
      background: $inactive-tab;
      color:$brown;
      font-size: 1.25rem;
      font-family: $header-font-family;
    }
    &.is-active a{
      background: $dark-gold;
    }
  }
  .tabs-content{
    background: $light-yellow;
    border:none;
  }

  .intro{
    background: $light-yellow;
    color:$dark-gold;
    font-size: 14px;
    padding: 20px;
    *:last-child{
      margin: 0;
    }
  }



}
