.tabs{
  border:none;
  border-bottom: 1px solid $dark-gold;
}
.tabs-title{
//margin-right: 5px;
a{
  background: $light-gold;
  color:$dark-gold;
  font-family: $header-font-family;
  font-size: 0.9rem;
  padding:0.75rem;
  @include breakpoint(medium){
    font-size: 1.125rem;
    padding: 1.25rem 1.5rem;
  }
  &:hover, &:focus{
    background: darken($light-gold, 5%);
    color:darken($dark-gold, 5%);
  }
}
&.is-active{
  a{
    background: $dark-gold;
    color:$white;
  }
}
}
.tabs-content{
  border:0;
  .tabs-panel{
    padding: 0;
  }
}
