#top-nav{
  background-color: $gold;

  @include fullscreen-background;
  background-image:url('/storage/app/media/BGs3_top_header.png');
  background-blend-mode: screen;

  padding:8px 0;
  a{
    color:$white;
  }


  .social-media-links{

    a{
      margin:0 15px;
      opacity:0.8;
      &:hover, &:focus{
        opacity: 1;
      }
      &:first-child{
        margin-left: -7px;
      }
      &:last-child{
        margin-right: -7px;
      }
    }
    ion-icon{
      width: 20px;
      height: 20px;
      position: relative;
      top: 3px;
    }
    display: flex;
    align-content: center;
  }

  .search-wrapper{
    max-width: 200px;
  }

}

.search-wrapper{
  position: relative;
  input{
    margin: 0;
    height:36px;
    border-radius: 3px;
    font-size: 14px;
    width: 100%;
  }
  a.submit{
    position: absolute;
    color:$green!important;
    @include vertical-center;
    right:10px;
    ion-icon{
      width: 20px;
      height: 20px;
      position: relative;
      top: 3px;
    }
  }
}


#topbar-logo{
  display: block;
  max-width: 200px;
  padding: 15px 0;
  @include breakpoint(large){
    max-width: 270px;
  }
}




#middle-top-bar{
  border: none;
  background: rgba(179, 153, 93, .2);
  padding: 0;
  position: relative;
  //min-width: 200px;
  &:before{
    border-top:1px solid $white;
    position: absolute;
    top:0;
    display: block;
    content:"";
    width: 100%;
    left:0;
  }

  ion-icon{
    width: 30px;
    height: 30px;
    color:$green;
    position: relative;
    top:4px;
  }

}


#topbar-right{
  position: relative;
  z-index: 1;

  width: 100%;
  @include breakpoint(medium down){
    margin: 0;
  }
  @include breakpoint(large){
    width: 400px;
  }

  #paliament-sitting{
    //background-color: $green;
    @include fullscreen-background;
    background-image:url('/storage/app/media/bg-2_small.png');
    //background-blend-mode: multiply;

    button{
      background: $gold;
      color:$white;
      padding:15px;
      text-transform: uppercase;
      font-family: $font-dax;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }

  #watch-listen-links{

    .menu{
      width: 100%;
    }
    @include xy-grid();
    li{
      background: $gold;
      border-bottom:1px solid rgba(255, 255, 255, .2);
      @include breakpoint(small only){
        border-right: 1px solid rgba(255, 255, 255, .2);
      }
      a{
        @include breakpoint(medium down){
          text-align: center;
          font-size: 0.9rem;
        }
        color:$white;
        text-transform: uppercase;
        font-size: 1rem;
        font-family: $font-dax;
        font-weight: 600;
      }
    }
  }

}

.have-your-say{
  position: absolute;
  right:0;
  height: 100%;
  border:none;
  background-color: $green;
  min-width: 200px;
  @include fullscreen-background;
  background-image:url('/storage/app/media/BGs3.png');
  background-blend-mode: multiply;
}


.nav-icon{
  margin-right: 10px;
  width: 40px;
  text-align: center;
  display: inline-block;
  @include breakpoint(medium down){
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: block;
  }
}



#parliament-sitting{
  background: $green;
}


#desktop-nav{
  z-index: 999;
  border-bottom: 4px solid $yellow;
  background-color: $dark-gold;
  .menu a{
    background: none;
    color:$white;
  }

  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after{
    //border: 6px solid transparent transparent transparent $white;
    //display: none;

  }

  .is-dropdown-submenu-parent a::after{
    right: 10px!important;
  }
  .is-submenu-item a{
    font-size: 0.9rem;
    line-height: 1.2;
    &:hover, &:focus{
      background: darken($green, 5%);
    }
  }


    #main-horiz-menu > li:first-child > a[href="/"]{
      text-indent: -9999px;
      font-size: 0;
      padding: 1.25rem;
      &:before{
        content:"";
        background-image:url('/storage/app/media/icon-home.png');
        width: 20px;
        height: 20px;
        display: block;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }

#main-horiz-menu > li {
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(255,255,255,0.2);
  &:first-child{
    border-left: 1px solid rgba(255,255,255,0.2);
  }
  &:hover, &:focus{
    background-color: $green;
  }
}
  #main-horiz-menu > li > a{
    font-family: $font-dax;
    font-weight: 600;
    color:$white;
    padding: 1.25rem 1rem;
    text-transform: uppercase;
    &:after{
      display: none;
    }
  }
}

#main-horiz-menu{


    .is-dropdown-submenu{
      min-width: 260px;
      background: $green;
      border-color: darken($green, 5%);
      //max-height: calc(100vh - 256px);
      //overflow: auto;
      //padding: 0.25rem;
      //padding-top: 0.25rem;
      //padding-bottom: 0.25rem;
      li{
        a{
          padding: 0.5rem 0.75rem 0.5rem 1rem!important
        }
        /*&:first-child{
          a{
            padding: 0.75rem 0.75rem 0.35rem 1rem!important
          }
        }
        &:last-child{
          a{
            padding: 0.35rem 0.75rem 0.75rem 1rem!important
          }
        }*/
      }
    }

}
/*
.mega-menu{

  @include xy-grid();
  &.is-active{
    background: $green;
  }




  .mega-child{
    position: relative;
    display: block!important;
    left: 0!important;
  }
  .mega-parent{

      @include xy-cell(3);
      & > a{
        font-family: $font-dax;
        font-weight: 600;
        font-size: 1rem!important;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
  }

  .submenu{
    background: transparent;
    border:none;
    margin-bottom: 1rem;
  }


  & > ul.submenu{
    margin-top: -4px;
    border-bottom: 4px solid $yellow;
    margin-bottom: 1rem;
    background: $green;
    padding-top: 1rem;
    width: 100vw;
    max-width: calc(1200px - 2rem);
    left: 50%!important;
    transform: translateX(-50%);
    right:auto!important;
    position: fixed;
    flex-wrap: wrap!important;
    flex-direction: row!important;
  }
  .is-dropdown-submenu.js-dropdown-active{
    display: flex!important;
  }

}
*/


.site-header{
 z-index: 99;
 background: $white;
}


.vertical.menu{
  a{
    padding: 0.5rem 1rem!important;
    line-height: 1.2;
  }
  .is-submenu-item a{
    padding: 0.5rem 1rem!important;
  }
  li{
    border-top: 1px solid #ccc;
  }
.is-accordion-submenu-parent{

  & > a{
    position: relative;
    &:after{
      display: block;
      width: 0;
      height: 0;
      border: inset 4px;
      content: '';
      border-right-width: 0;
      border-left-style: solid;
      border-color: transparent transparent transparent $green;
      position: absolute;
      right: 15px;
      transform: rotate(90deg);
      top: 50%;
      margin-top: -2px;
    }
  }
  &[aria-expanded="true"]{
    & > a {
      &:after{
        transform: rotate(-90deg);
      }
    }
  }
}
}



.pagination a, .pagination span, .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a;
    background:$light-gold;
    border-radius: 2px;
    text-transform: uppercase;
}
.pagination span{
  background: $gold;
  color:$white;
}
