
#bill-filter{
  margin-top: 30px;
  margin-bottom: 30px;
  background: $light-gold;
  border-bottom: 3px solid $dark-gold;
  padding: 15px;
  @include breakpoint(medium down){
    padding-bottom: 5px;
  }

  .filter-instruction{
    font-size: 14px;
    color: $dark-gold;
      @include breakpoint(medium down){
        margin-bottom: 10px;
      }
    strong{
      font-family: $header-font-family;
    }
  }

  .filter-options{
    input:not([type="submit"]), select{
      font-size: 14px;
      @include xy-cell();
      @include breakpoint(medium){
        @include xy-cell(6);
      }
      @include breakpoint(large){
        @include xy-cell(1 of 6);
      }
      margin-bottom: 0;
      @include breakpoint(medium down){
        margin-bottom: 10px;
      }
    }
    .buttons-wrapper{
      @include xy-grid;
      @include xy-cell(12);

      @include breakpoint(medium){
        @include xy-cell(6);

      }
      @include breakpoint(large){
        @include xy-cell(1 of 6);
      }
      .button{
      @include xy-cell(6, $gutters:10);
      @include breakpoint(medium down){
        width: calc(50% - 5px);
        margin-bottom: 10px;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{margin-right:0;}
      }
      }
    }

  }
}


.bills-tabs{
  display: flex;
  flex-wrap: wrap;
  .tabs-title a{
      padding: 15px 10px;
      height: 100%;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
  }
  .tabs-title{
    float: none;
    margin-right: 5px;
    max-width: calc(100%/3 - 5px);
    @include breakpoint(small only){
      margin-bottom: 5px;
    }
    @include breakpoint(medium){
      max-width: calc(100%/7 - 5px);
    }
  }
}

td .label{
  display: block;
}
.label{
  text-align: center;
  border-radius: 2px;
}

.label.green{
  background: $green;
  color:$white!important;
  font-weight: bold;
}
.label.gold{
  background: $gold;
  color:$white!important;
  font-weight: bold;
}



#status-tracker{


  margin-top: 30px;
  @include breakpoint(small only){
    margin-bottom: 30px;
  }
  //@include breakpoint(medium){
    &:before{
      content:"";
      border-top: 4px solid $gold;
      padding-top: 30px;
      width: 100%;
      display: block;
    }
  //}


  position: relative;
  &:after{
    content:"";
    position: absolute;
    z-index: -1;

    display: block;
  }
  .status{
    @include breakpoint(small only){
      padding-left: 40px;
    }
    h6{
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color:$brown;
      margin-bottom: 5px;
    }
    .date{
      font-size: 10px;
    }

      @include breakpoint(small only){
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        &:last-child{
          margin-bottom: 0;
        }
        .status-detail{
          padding-left: 15px;
        }

      }
    .status-detail{
      @include breakpoint(medium){
        text-align: center;
      }
    }

    &:last-child{
      .status-block:after{
        display: none;
      }
    }
    .status-block{
      @include breakpoint(small only){
        width: 25%;
        position: relative;
        &:after{
          content:"";
          position: absolute;
          display: block;
          height: 20px;
          bottom:-23px;
          left: 50%;
          border-right: 1px dashed darken($light-gold, 10%);
        }
      }
      @include breakpoint(medium){
        margin-bottom: 10px;
        &:after{
          content:"";
          position: absolute;
          display: block;
          width: 60px;
          left:100%;
          top: 50%;
          z-index: -1;
          border-top: 1px dashed darken($light-gold, 10%);
        }
      }

      position: relative;
      border:1px solid darken($light-gold, 10%);
      background: $light-gold;
      color:$brown;

      &.active{
        background: $green;
        color:$white;
        border-color:$green;
        & + h6{
          color:$green;
        }
      }
      .content{
        padding: 10px;
        @include absolute-center;
        text-align: center;
      }
      &:before{
        content:"";
        padding-top: 100%;
        display: block;
      }
    }

    @include breakpoint(medium){
      padding-bottom: 40px;
    }
    position: relative;
  }


  .pip{
    position: absolute;
    @include horizontal-center;
    bottom:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 20px 15px;
    border-color: transparent transparent $gold transparent;

    @include breakpoint(small only){
      transform:rotate(90deg);
      position: absolute;
      left: -5px;
      top:50%;
      margin-top: -10px;
    }
  }
  .status-bar{
    @include breakpoint(small only){
      position: absolute;
      top:0;
      bottom:0;
      left: 0;
      border-right:4px solid $gold;
      height: 100%;
      display: block;
    }
    @include breakpoint(medium){
      border-top:4px solid $gold;
      display: block;
      margin-bottom: 30px;
    }
  }


  .status-flag{
    @include breakpoint(small only){
      margin-left: 40px;
    }
    margin-bottom: 30px;
  }

}


#latest-bill{
  height: 100%;
  color:$white;
  padding: 30px;
  background: $green;
  @include fullscreen-background;
  background-image:url('/storage/app/media/BGs3_top_header.png');
  background-blend-mode: multiply;
}

#bill-submissions{
  height: 100%;
  color:$white;
  padding: 30px;
  background: $dark-yellow;
  @include fullscreen-background;
  background-image:url('/storage/app/media/BGs3_top_header.png');
  background-blend-mode: multiply;
}

#versions-history{
  margin-top: 30px;
}
