#latest-bill-acts{

  color:$gold;
  font-size: 14px;

  h4{
    background: $dark-gold;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  border:1px solid $light-gold;

  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child,
  .tabs li:nth-last-child(n+1):nth-last-child(-n+2):first-child ~ li {
    width: 50%;

  }
  .tabs{
    border:none;
    background: $inactive-tab;
    border-bottom: 3px solid $dark-gold;
  }
  .tabs-title{
    color:$brown;
    a{
      background: $inactive-tab;
      color:$brown;
      font-size: 1.125rem;
      font-family: $header-font-family;
      text-transform: uppercase;
      padding: 15px;
    }
    &.is-active a{
      background: $dark-gold;
    }
  }
  .tabs-content{
    background: $white;
  //  color:$gold;
  border:none;
  }

  .button-wrapper{
    text-align: right;
    padding: 10px;
    .button{
      margin: 0;
    }
    border-top:1px solid $light-gold;

  }


.item-row{
    border-top:1px solid $light-gold;
}

  .publication{
    margin-bottom: 0.5rem;
  }

  .publication-wrapper{
    font-size: 0.8rem;
    font-family: $header-font-family;
  }

  border-bottom: 3px solid $dark-gold;

  .speech-bubble{
    text-align: center;
    background: $light-gold;
    padding: 10px;
    padding-bottom: 3px;
    svg{
      width: 26px;
      height: 24px;
    }
    a{
      width: 26px;
      height: 24px;
    }
  }
  .bill-status{
    background: $gold;
    width: 100%;
    border-radius: 0;
    padding: 2px;
    width: 100%;
    height: auto;
    font-size: 10px;
    font-weight: bold;
    margin: 0;
  }

  .bill-id{
    .cell{
      padding: 10px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    text-align: center;
    font-size: 12px;
    background: $green;
    color:$white;
    a{
      color: $white;
      text-align: center;

    }
  }

}
