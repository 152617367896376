// Looking For...
#looking-for{
  width: 100%;
  height: auto;
  background: $light-gold;
  h4{
    background: $dark-gold;
    padding:10px 15px;
    color:$white;
    text-transform: uppercase;
    margin-bottom: 0;
    img{
      height: 46px;
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
  }
  ul{
    list-style: none;
    padding: 0;margin:0;

    li{
      background: lighten($gold, 40%);
      border-bottom: 1px solid lighten($gold, 30%);
      padding: 10px;
      padding-left: 25px;
      position: relative;
      &:before{
        content:"";
        border-left: 15px solid $dark-yellow;
        display: block;
        position: absolute;
        height: calc(100% - 4px);
        left: 0;
        top:2px;
      }
      a{
        color: $green;
        text-transform: uppercase;
        font-family: $header-font-family;
        font-weight: 600;
      }
    }
  }
  border-bottom: 5px solid $dark-gold;
}

.grid-x > #looking-for{
    height: 100%;
}
