#all-members-tabs-wrapper{



  .tabs-content{
    border:0;
  }
  .tabs-panel.is-active, .member-list{
    margin-left: -10px;
    margin-right: -10px;
    padding: 0;
    @include xy-grid;
    & > div{
      @include xy-cell(6);
      @include breakpoint(medium){
        @include xy-cell(1 of 4);
      }
      @include breakpoint(large){
        @include xy-cell(1 of 6);
      }
      margin-bottom: 40px;
      .member-image{
        border-top: 3px solid $dark-gold;
        margin-bottom: 8px;
      }
    }
  }

  member-detail{
    text-align: center;
    font-family: $header-font-family;
  }

  .tabs{
    margin-bottom: 20px;
    border:none;
    border-bottom: 1px solid $dark-gold;
  }
.tabs-title{
  margin-right: 5px;
  a{
    background: $light-gold;
    color:$dark-gold;
    font-family: $header-font-family;
    font-size: 0.9rem;
    padding:0.75rem;
    @include breakpoint(medium){
      font-size: 1.125rem;
      padding: 1.25rem 1.5rem;
    }
  }
  &.is-active{
    a{
      background: $dark-gold;
      color:$white;
    }
  }
}


}

#all-members-filter{
  margin-bottom: 30px;
  background: $light-gold;
  border-bottom: 3px solid $dark-gold;
  padding: 15px;
  @include breakpoint(medium down){
    padding-bottom: 5px;
  }

  .filter-instruction{
    font-size: 14px;
    color: $dark-gold;
      @include breakpoint(medium down){
        margin-bottom: 10px;
      }
    strong{
      font-family: $header-font-family;
    }
  }

  .filter-options{
    input:not([type="submit"]), select{
      font-size: 14px;
      @include xy-cell();
      @include breakpoint(medium){
        @include xy-cell(6);
      }
      @include breakpoint(large){
        @include xy-cell(1 of 6);
      }
      margin-bottom: 0;
      @include breakpoint(medium down){
        margin-bottom: 10px;
      }
    }
    .buttons-wrapper{
      @include xy-grid;
      @include xy-cell(12);

      @include breakpoint(medium){
        @include xy-cell(6);

      }
      @include breakpoint(large){
        @include xy-cell(1 of 6);
      }
      .button{
      @include xy-cell(6, $gutters:10);
      @include breakpoint(medium down){
        width: calc(50% - 5px);
        margin-bottom: 10px;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{margin-right:0;}
      }
      }
    }

  }
}


#member-details{
  h2{
    color: $green;
    margin-bottom: 1rem;
  }
  .label{
    padding: 10px 15px;
    border-radius: 1px;
    background-color: $dark-gold;
    margin-bottom: 1rem;
  }

  .member-image{
    margin-bottom: 1rem;
    img{
      width: 100%;
    }
  }
  #contact-details{
    border-top:1px solid $light-gold;
    padding-top: 20px;
    margin-top: 30px;
    color:$dark-gold;
    ion-icon{
      position: relative;
      top:10px;
      margin-right: 15px;
      width: 30px;
      height: 30px;

    }
  }

  h3{
    background: $light-gold;
    position: relative;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-transform: uppercase;
    &:before{
      content:"";
      border-top: 1px solid $white;
      top:1px;
      left: 1px;
      width: 100%;
      position: absolute;
    }
  }

}
