#intro-page{
  background: $light-gold;
  padding: 30px 20px;
  border-top: 3px solid $dark-gold;
  border-bottom: 3px solid $dark-gold;
  position: relative;
  &:before, &:after{
    content:"";
    display: block;
    width: 100%;
    border-top: 8px solid $white;
    position: absolute;
    top:0;
    left: 0;
  }
  &:after{
    top:auto;
    bottom:0;
  }
  hr{
    border-color: darken($light-gold, 20%);
  }

  h5{
    color: $dark-gold;
  }
  .button{
    margin-top: 20px;
  }
}



#bill-submissions-wrapper{
  .tabs{
    margin-bottom: 20px;
    .tabs-title{
      margin-right: 5px;
    }
  }
}


#bill-submission-page{

  .trumbowyg-box{
    background: $white;
  }

  .button{
    margin-bottom: 20px;
  }
  & > .tabs{
    border:none;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;

    counter-reset: my-sec-counter;
    .tabs-title a::before {
      background: $dark-gold;
      border-right: 1px solid $white;
      padding: 13px 15px;
      color:$white;
      font-weight: 600;
      margin-right: 15px;
      margin-left: -15px;
      margin-top:-15px;
      margin-bottom: -15px;
      counter-increment: my-sec-counter;
      content: counter(my-sec-counter, decimal-leading-zero);
    }

    .tabs-title{
      margin-left: 5px;
      margin-right: 5px;
      opacity: 0.5;
      font-size: 1rem;
      text-transform: uppercase;
      a{
        padding: 15px;
        background: $light-gold;
        color:$dark-gold;
        &:hover, &:focus{
          background: $light-gold;
          color:$dark-gold;
        }
      }
      &.is-active{
        opacity: 1;
        a{
          background: $dark-yellow;
          color:$white;
          &:hover, &:focus{
            background: $dark-gold;
            color:$white;
          }
        }
      }
      &.is-complete{
        a{
          background: $green;
          color:$white;
          &::before{
            background: $green;
          }
        }
      }
    }
  }






  .tabs-panel{
    background: $light-gold;
    padding-bottom: 0;
  }

  h5{
    color:$dark-gold;
  }
}


#stay-informed-banner{
  color:$white;
  margin-top:30px;
  border-top: 15px solid $dark-yellow;
  padding: 40px;
  background-color: $dark-gold;
  @include fullscreen-background;
  background-image:url('/storage/app/media/bg-2_footer.png');
  background-blend-mode: multiply;
  h3{
    text-transform: uppercase;
  }
}
