#parliament-tv-widget{
  background-color: $orange;


  .button-content{
    background-image:url('/storage/app/media/bg-2_small.png');
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: $orange;
    background-position: -100px -50px;
    position: relative;
    padding: 20px;

    *{
      position: relative;
      z-index: 1;
    }
    &:before{
      content:"";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
      background: rgba(0,0,0,0.3);
    }

    .button{
      margin-bottom: 0;
      min-width: 120px;
    }

  }

  header{
    background-color: $orange;
    padding: 20px;
    padding-bottom: 0;
    img{
      width: 80px;
      top: -6px;
      position: relative;
    }
    h4{
        text-transform: uppercase;
        margin-bottom: 0;
    }
    font-family: $header-font-family;
    font-size: 1.05rem;
    span{
      text-transform: uppercase;

      font-size: 1.125rem;
    }
  }

  color:$white;
  a{
      color:$white;
  }
  p{
      color:$white;
  }


}
